import axios from '@/axios'

const route = "organization/construction";

export default {
    getAll(ItemsPerPage, page, search) {
        return axios.get(route + '?page=' + page + '&items_per_page=' + ItemsPerPage + '&search=' + search)
    },

    store(construction) {
        return axios.post(route, construction)
    },

    update(construction) {
        return axios.put(route + "/" + construction.id, construction)
    },

    destroy(construction) {
        return axios.delete(route + "/" + construction.id)
    },

    getAllStates(){
        return axios.get(route + "/states")
    },

    getAllCities(state_code){
        return axios.get(route + "/cities/"+state_code)
    },

    getAllContructions(){
        return axios.get(route + "/constructions")
    }

}
