<template>
  <v-container>
    <v-data-table
      :loading="loading_data_table"
      :headers="headers"
      :items="desserts"
      :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate"
      :options.sync="options"
      :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions:[5,10,15],
        showFirstLastPage:true
      }"
       sort-by="corporate_name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Obras</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
           <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Localizar"
              @click:append="searchItems()"
              @change="searchItems()"
              single-line
              hide-details
              class="mr-8"
          ></v-text-field>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                rounded
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                > <v-icon small >mdi-plus</v-icon>Nova Obra</v-btn
              >
            </template>
            <v-card>
             <div class="title_body">
                  <span class="text_title text-h6">{{ formTitle }}</span>
                  <span class="btn_close"><v-btn icon dark @click="dialog = false" ><v-icon dark > mdi-close </v-icon></v-btn> </span>
             </div>
              <v-card-text>

                  <v-form class="px-3" @submit.prevent="submit()">
                    <v-row>
                      <v-col>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="editedItem.name"
                          label="Nome"
                        ></v-text-field
                      ></v-col>
                      <v-col v-if="editedIndex <= -1">
                        <v-select
                        hide-details
                          v-model="editedItem.construction_mirror_id"
                          outlined
                          :items="construtions"
                          item-text="name"
                          item-value="id"
                          clearable
                          label="Clonar Obra"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        ><v-text-field
                          hide-details
                          outlined
                          v-model="editedItem.address"
                          label="Endereço"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row> </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          hide-details
                          v-model="editedItem.status"
                          outlined
                          :items="['Planejamento','Ativa','Parada','Cancelada','Concluída']"
                          label="Status"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          hide-details
                          v-model="editedItem.state_code"
                          outlined
                          :items="states"
                          item-text="name"
                          item-value="code"
                          label="Estado"
                          @change="getAllCities"

                        ></v-select>
                        </v-col>
                      <v-col>
                        <v-select
                          hide-details
                          :loading="loadingCities"
                          v-model="editedItem.city_code"
                          outlined
                          :items="cities"
                          item-text="name"
                          item-value="code"
                          label="Cidade"

                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="5">
                        <v-select
                          hide-details
                          v-model="editedItem.type"
                          outlined
                          :items="['Casa', 'Predio', 'Outro']"
                          label="Tipo"
                        ></v-select>
                      </v-col>

                      <v-col>
                        <v-menu
                          v-model="menu_date2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              hide-details
                              outlined
                              v-model="formatDateStart"
                              label="Data Início"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.date_start"
                            @input="menu_date2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col>
                        <v-menu
                          v-model="menu_date1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              hide-details
                              outlined
                              v-model="formatDateEnd"
                              label="Data Final"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.date_end"
                            @input="menu_date1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        ><v-text-field
                          hide-details
                          outlined
                          v-model="editedItem.responsible"
                          label="Responsável"
                        ></v-text-field
                      ></v-col>
                      <v-col cols="3">
                        <vuetify-money
                          hide-details
                          label="Valor (R$)"
                          outlined
                          v-model="editedItem.value"
                          valueWhenIsEmpty=""
                          :options="{
                            locale: 'pt-BR',
                            prefix: 'R$',
                            length: 11,
                            precision: 2,
                          }"
                        />

                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-textarea
                          hide-details
                          rows="2"
                          outlined
                          label="Descrição"
                          v-model="editedItem.description"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>

              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions >
                <v-spacer></v-spacer>
                <v-btn x-large class="mx-0 mt-3 mr-1" text @click="dialog = false"
                  >Fechar</v-btn
                >
                <v-btn
                  x-large
                  width="150"
                  class="primary mx-0 mt-3 "
                  :loading="btnLoadingSave"
                  :disabled="btnLoadingSave"
                  @click="save"
                  >Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="550px">
            <v-card>
              <v-card-title class="text-h5"
                >Tem certeza de que deseja excluir este item ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" @click="closeDelete">Cancelar</v-btn>
                <v-btn class="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>

  <!-- loading persistent -->
<v-dialog
      v-model="dialogPersistent"

      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Por farvor aguarde, espelhando obra.
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
import service from "./service";
export default {
  data: () => ({
    dialogPersistent:false,
    totalStagesPaginate : 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate:0,
    options: {},
    construtions:[],
    search:'',
    loadingCities:false,
    menu_date1: false,
    menu_date2: false,
    btnLoadingSave: false,
    loading_data_table: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Nome", align: "start", sortable: false, value: "name" },
      { text: "Tipo", value: "type" },
      { text: "Status", value: "status" },
      { text: "Cidade", value: "city.name" },
      { text: "Estado", value: "state.name" },
      { text: "", align:"end" ,value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      address: "",
      status: "",
      state_code: "",
      city_code: "",
      type: "",
      date_start: "",
      date_end: "",
      value: "",
      responsible: "",
      description: "",
      construction_mirror_id:"",
    },
    defaultItem: {
      id: "",
      name: "",
      address: "",
      status: "",
      state: "",
      city: "",
      type: "",
      date_start: "",
      date_end: "",
      value: "",
      responsible: "",
      description: "",
      construction_mirror_id:"",
    },
    states: [],
    cities: [],
  }),

  computed: {
    formatDateStart() {
      let date = this.editedItem.date_start;
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDateEnd() {
      let date = this.editedItem.date_end;
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar Obra" : "Editar Obra";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
    },
  },

  created() {
    this.initialize();
    this.getAllStates();
    this.getAllContructions();
  },

  methods: {
    initialize() {
      this.loading_data_table = true;

      const { page, itemsPerPage } = this.options;

      service.getAll(itemsPerPage||10,page,this.search).then((response) => {
        this.desserts = response.data.data;
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page

      }).finally(()=>{
        this.loading_data_table = false;
      });
    },
    searchItems(){
      this.options = {}
      this.initialize()

    },
    getAllStates(){
      service.getAllStates().then((response)=>{
        this.states = response.data
      }).catch(()=>{
        this.$toast.error("Não foi possivel carregar os estados.");
      })
    },
    getAllContructions(){
      service.getAllContructions().then((response)=>{
        this.construtions = response.data
      }).catch(()=>{
        this.$toast.error("Não foi possivel carregar as obras para espelhamento.");
      })
    },
    getAllCities(){
      this.loadingCities=true
      let state_code = this.editedItem.state_code
      service.getAllCities(state_code).then((response)=>{
        this.cities = response.data
      }).catch(()=>{
        this.$toast.error("Não foi possivel carregar as cidades do estado.");
      }).finally(()=>{
        this.loadingCities=false
      })
    },



    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let editedIndex = this.editedIndex;
      service.destroy(this.editedItem).then(() => {
        this.desserts.splice(editedIndex, 1);
        this.$toast.success("Deletado com sucesso.");
      });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.btnLoadingSave = true;
      if (this.editedIndex > -1) {
        //update
        let indice = this.editedIndex;
        service
          .update(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            Object.assign(this.desserts[indice], response.data);
            console.log(this.desserts[indice])
            this.close();
            this.$toast.success("Salvo com sucesso.");
          })
          .catch(() => {
            this.btnLoadingSave = false;
          });
      } else {
        //store
        if(this.editedItem.construction_mirror_id !=""){this.dialogPersistent = true} //se a obra for espelhada de outra abri o loading pesistent
        service
          .store(this.editedItem)
          .then((response) => {
            this.desserts.push(response.data);
            this.close();
            this.$toast.success("Obra criada com sucesso.");
            if (this.desserts.length == 1){ this.initialize()}
          })
          .catch(() => {

          }).finally(()=>{
            this.dialogPersistent = false
            this.btnLoadingSave = false
          })
      }
    },
  },
};
</script>
